import { useState, useEffect, useRef } from "react";
import {
  Text,
  Box,
  List,
  ListItem,
  useDisclosure,
} from "@chakra-ui/react";
import { AnimatePresence, Reorder } from "framer-motion";

import TaskBox from "./TaskBox";
import NewTaskButton from "./NewTaskButton";
import NewTaskModal from "./NewTaskModal";

export default function TaskList() {
  const [tasks, setTasks] = useState(
    JSON.parse(localStorage.getItem("tasks")) ?? []
  );

  useEffect(
    () =>
      localStorage.setItem("tasks", JSON.stringify(tasks)),
    [tasks]
  );

  const constraintsRef = useRef();

  const removeTask = id => {
    const newState = tasks.filter(task => task.id !== id);

    setTasks(newState);
  };

  const {
    isOpen: isNewTaskModalOpen,
    onOpen: onNewTaskModalOpen,
    onClose: onNewTaskModalClose,
  } = useDisclosure();

  const newTask = (task, subTasks) => {
    task.id = Date.now();
    task.subTasks = subTasks;

    setTasks([task, ...tasks]);
  };

  return (
    <Box width="full">
      <Text
        fontSize="xl"
        fontWeight="bold"
        align="left"
        paddingBottom={2}
        borderBottom="solid 2px"
      >
        Tasks
      </Text>
      <NewTaskButton onOpen={onNewTaskModalOpen} />
      <NewTaskModal
        isOpen={isNewTaskModalOpen}
        onClose={onNewTaskModalClose}
        newTask={newTask}
      />
      <List
        as={Reorder.Group}
        axis="y"
        values={tasks}
        onReorder={setTasks}
        spacing={4}
        marginTop={4}
        ref={constraintsRef}
        layoutScroll
        marginBottom={20}
      >
        <AnimatePresence>
          {tasks.map(item => (
            <ListItem
              as={Reorder.Item}
              key={item.id}
              value={item}
              dragTransition={{ bounceStiffness: 600 }}
              dragConstraints={constraintsRef}
              dragElastic={0.00000001}
              whileDrag={{
                zIndex: 400,
              }}
              exit={{ opacity: 0 }}
            >
              <TaskBox
                task={item}
                removeTask={removeTask}
              />
            </ListItem>
          ))}
        </AnimatePresence>
      </List>
    </Box>
  );
}
