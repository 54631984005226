import React, { useEffect, useContext } from "react";
import {
  ChakraProvider,
  ColorModeScript,
  Container,
} from "@chakra-ui/react";

import theme from "./constants/theme";
import PomodoroTimer from "./components/PomodoroTimer";
import TaskList from "./components/TaskList";
import Header from "./components/Header";
import SettingsModal from "./components/SettingsModal";
import SettingsContext from "./contexts/SettingsContext";

export default function App() {
  // Force dark mode
  localStorage.setItem("chakra-ui-color-mode", "dark");

  // Save contexts to local storage
  let settings = useContext(SettingsContext);

  useEffect(() => {
    localStorage.setItem(
      "contexts.settings.durations.workTime",
      settings.durations.workTime
    );
    localStorage.setItem(
      "contexts.settings.durations.shortBreak",
      settings.durations.shortBreak
    );
    localStorage.setItem(
      "contexts.settings.durations.longBreak",
      settings.durations.longBreak
    );
  });

  return (
    <>
      <ColorModeScript
        initialColorMode={theme.config.initialColorMode}
      />
      <ChakraProvider theme={theme}>
        <Container maxW="container.md" align="center">
          <Header />
        </Container>
        <Container maxW="md" align="center" marginTop={20}>
          <PomodoroTimer />
        </Container>
        <Container maxW="lg" align="center" paddingTop={16}>
          <TaskList />
        </Container>
        <SettingsModal />
      </ChakraProvider>
    </>
  );
}
