import { IconButton } from "@chakra-ui/react";
import {
  FiSkipBack as BackwardSkipIcon,
  FiSkipForward as ForwardSkipIcon,
} from "react-icons/fi";

export default function SkipButton({ direction, onClick }) {
  return (
    <IconButton
      icon={
        direction === "forward" ? (
          <ForwardSkipIcon />
        ) : (
          <BackwardSkipIcon />
        )
      }
      onClick={onClick}
      fontSize="xl"
    />
  );
}
