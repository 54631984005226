import { Text } from "@chakra-ui/react";

export default function TimerText({ time }) {
  return (
    <Text fontSize="8xl" fontFamily="Roboto">
      {new Date(null, null, null, null, null, time)
        .toISOString()
        .substring(14, 19)}
    </Text>
  );
}
