import { useState, useEffect } from "react";
import {
  Checkbox,
  Box,
  HStack,
  VStack,
  IconButton,
  Spacer,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useSpring, animated } from "react-spring";
import useMeasure from "react-use-measure";
import { HiTrash } from "react-icons/hi";

import ExpandButton from "./ExpandButton";

export default function TaskBox({ task, removeTask }) {
  const [checkedItems, setCheckedItems] = useState(
    JSON.parse(
      localStorage.getItem(`subTasks.checked.${task.id}`)
    ) ?? new Array(task.subTasks.length).fill(false)
  );

  useEffect(() => {
    localStorage.setItem(
      `subTasks.checked.${task.id}`,
      JSON.stringify(checkedItems)
    );

    return () =>
      localStorage.removeItem(
        `subTasks.checked.${task.id}`
      );
  }, [checkedItems, task.id]);

  const allChecked = checkedItems.every(Boolean);

  const [mainCheckboxChecked, setMainCheckboxChecked] =
    useState(
      localStorage.getItem(
        `subTasks.checked.${task.id}.mainCheckboxChecked`
      ) === "true" ?? false
    );

  useEffect(() => {
    localStorage.setItem(
      `subTasks.checked.${task.id}.mainCheckboxChecked`,
      mainCheckboxChecked
    );

    return () =>
      localStorage.removeItem(
        `subTasks.checked.${task.id}.mainCheckboxChecked`
      );
  }, [mainCheckboxChecked, task.id]);

  const isIndeterminate =
    checkedItems.some(Boolean) && !allChecked;
  const setCheckedAtIndex = (index, isChecked) => {
    const newState = new Array(checkedItems.length);

    for (let i = 0; i < checkedItems.length; i++) {
      if (i !== index) {
        newState[i] = checkedItems[i];
      } else {
        newState[i] = isChecked;
      }
    }

    setCheckedItems(newState);
  };
  const setAllItemsChecked = isChecked => {
    setCheckedItems(
      new Array(checkedItems.length).fill(isChecked)
    );
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleIsExpanded = () => setIsExpanded(!isExpanded);

  const getCheckboxColorScheme = () => {
    if (allChecked) {
      return "green";
    } else {
      if (checkedItems.every(item => item === false)) {
        return "red";
      } else {
        return "yellow";
      }
    }
  };

  const [ref, bounds] = useMeasure();
  const sizeSpring = useSpring({
    height: isExpanded ? bounds.height : 0,
  });

  const renderMainCheckbox = () => {
    return task.subTasks.length === 0 ? (
      <Checkbox
        size="lg"
        spacing={3}
        colorScheme="green"
        isChecked={mainCheckboxChecked}
        onChange={e => {
          setMainCheckboxChecked(e.target.checked);
        }}
      >
        {task.description}
      </Checkbox>
    ) : (
      <Checkbox
        isChecked={allChecked}
        isIndeterminate={isIndeterminate}
        colorScheme={getCheckboxColorScheme()}
        onChange={e => {
          setAllItemsChecked(e.target.checked);
        }}
        size="lg"
        spacing={3}
      >
        {task.description}
      </Checkbox>
    );
  };

  const renderContent = () => {
    return (
      <>
        {renderMainCheckbox()}
        <Box
          as={animated.div}
          style={sizeSpring}
          overflow="hidden"
        >
          <VStack align="left" paddingLeft={5} ref={ref}>
            {task.subTasks.map((subTask, index) => (
              <Checkbox
                isChecked={checkedItems[index]}
                onChange={e =>
                  setCheckedAtIndex(index, e.target.checked)
                }
                colorScheme="green"
                key={subTask.id}
              >
                {subTask.description}
              </Checkbox>
            ))}
          </VStack>
        </Box>
      </>
    );
  };

  return (
    <HStack
      padding={5}
      background={useColorModeValue("gray.200", "gray.700")}
      alignItems="flex-start"
      _hover={{
        cursor: "pointer",
      }}
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      whileHover={{ scale: 1.02 }}
      borderRadius="xl"
    >
      <VStack
        align="left"
        alignSelf={isExpanded ? "flex-start" : "center"}
      >
        {renderContent()}
      </VStack>
      <Spacer />

      <IconButton
        icon={<HiTrash />}
        size="md"
        onClick={() => removeTask(task.id)}
      />
      {task.subTasks.length !== 0 && (
        <ExpandButton
          isExpanded={isExpanded}
          toggleIsExpanded={toggleIsExpanded}
        />
      )}
    </HStack>
  );
}
