import { Button } from "@chakra-ui/react";

export default function StartStopButton({
  isPaused,
  setIsPaused,
}) {
  const toggleRunning = () => setIsPaused(!isPaused);

  return (
    <Button onClick={toggleRunning} width="full">
      {isPaused ? "Start" : "Stop"}
    </Button>
  );
}
