import React from "react";

const SettingsContext = React.createContext({
  durations: {
    workTime:
      localStorage.getItem(
        "contexts.settings.durations.workTime"
      ) ?? 25,
    shortBreak:
      localStorage.getItem(
        "contexts.settings.durations.shortBreak"
      ) ?? 5,
    longBreak:
      localStorage.getItem(
        "contexts.settings.durations.longBreak"
      ) ?? 10,
  },
  customization: {
    colorScheme: "dark",
  },
});

export default SettingsContext;
