import { useContext } from "react";
import { IconButton } from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import ModalsContext from "../../../contexts/ModalsContext";

export default function SettingsButton() {
  const modals = useContext(ModalsContext);

  return (
    <IconButton
      icon={<SettingsIcon />}
      onClick={() => modals.settingsModal.onOpen()}
    />
  );
}
