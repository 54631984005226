import { IconButton } from "@chakra-ui/react";
import {
  HiChevronDoubleUp,
  HiChevronDoubleDown,
} from "react-icons/hi";

export default function ExpandButton({
  isExpanded,
  toggleIsExpanded,
}) {
  return (
    <IconButton
      icon={
        isExpanded ? (
          <HiChevronDoubleUp />
        ) : (
          <HiChevronDoubleDown />
        )
      }
      size="md"
      onClick={toggleIsExpanded}
    />
  );
}
