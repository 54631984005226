import { useState, useRef } from "react";
import {
  HStack,
  IconButton,
  List,
  ListItem,
  Text,
  Spacer,
  Input,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { HiTrash } from "react-icons/hi";
import { AddIcon } from "@chakra-ui/icons";
import { Reorder, AnimatePresence } from "framer-motion";

export default function SubTaskList({
  subTasks,
  setSubTasks,
}) {
  const boxColor = useColorModeValue(
    "gray.100",
    "gray.600"
  );

  const constraintsRef = useRef();

  const [subTaskDescription, setSubTaskDescription] =
    useState("");

  const addSubTask = description => {
    const newSubTasks = [
      ...subTasks,
      { id: Date.now(), description },
    ];

    setSubTasks(newSubTasks);
  };

  const removeSubTask = id => {
    const newSubTasks = subTasks.filter(
      subTask => subTask.id !== id
    );

    setSubTasks(newSubTasks);
  };

  return (
    <>
      <List
        spacing={2}
        align="stretch"
        as={Reorder.Group}
        axis="y"
        values={subTasks}
        onReorder={setSubTasks}
        ref={constraintsRef}
      >
        <AnimatePresence>
          {subTasks.map(subTask => (
            <ListItem
              as={Reorder.Item}
              key={subTask.id}
              value={subTask}
              dragTransition={{ bounceStiffness: 600 }}
              dragConstraints={constraintsRef}
              dragElastic={0.00000001}
              whileDrag={{
                zIndex: 400,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <HStack
                background={boxColor}
                borderRadius="lg"
              >
                <Text paddingY={2} paddingX={4}>
                  {subTask.description}
                </Text>
                <Spacer />
                <Box padding={2}>
                  <IconButton
                    icon={<HiTrash />}
                    onClick={() =>
                      removeSubTask(subTask.id)
                    }
                  />
                </Box>
              </HStack>
            </ListItem>
          ))}
        </AnimatePresence>
      </List>
      <HStack width="full" size="lg" marginTop={5}>
        <Input
          value={subTaskDescription}
          onChange={e =>
            setSubTaskDescription(e.target.value)
          }
        ></Input>
        <IconButton
          icon={<AddIcon />}
          onClick={() => {
            if (subTaskDescription.trim() === "") return;

            addSubTask(subTaskDescription);
            setSubTaskDescription("");
          }}
        />
      </HStack>
    </>
  );
}
