import { Box, useColorModeValue } from "@chakra-ui/react";

export default function ProgressBar({ percentage }) {
  return (
    <Box
      background={useColorModeValue("gray.200", "gray.600")}
      width="full"
      height="full"
      align="left"
      borderRadius="md"
      overflow="hidden"
    >
      <Box
        background="white"
        width={`${percentage}%`}
        height="100%"
        minHeight="2"
      ></Box>
    </Box>
  );
}
