import { HStack, Spacer, Heading } from "@chakra-ui/react";

import ToggleColorModeButton from "../ToggleColorModeButton";
import SettingsButton from "./SettingsButton";

export default function Header() {
  return (
    <HStack padding={5}>
      <Heading>Pomobyte.</Heading>
      <Spacer />
      <SettingsButton />
    </HStack>
  );
}
