import { useState, useContext } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
  Text,
  HStack,
  VStack,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useDisclosure,
} from "@chakra-ui/react";
import SettingsContext from "../../contexts/SettingsContext";
import ModalsContext from "../../contexts/ModalsContext";
import { SettingsIcon } from "@chakra-ui/icons";

export default function SettingsModal() {
  const settings = useContext(SettingsContext);

  const [workTimeDuration, setWorkTimeDuration] = useState(
    settings.durations.workTime
  );
  const [shortBreakDuration, setShortBreakDuration] =
    useState(settings.durations.shortBreak);
  const [longBreakDuration, setLongBreakDuration] =
    useState(settings.durations.longBreak);

  const modals = useContext(ModalsContext);
  modals.settingsModal = useDisclosure();
  const { isOpen, onOpen, onClose } = modals.settingsModal;

  const handleClose = () => {
    settings.durations.workTime = workTimeDuration;
    settings.durations.shortBreak = shortBreakDuration;
    settings.durations.longBreak = longBreakDuration;

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack align="center">
            <SettingsIcon />
            <Heading size="md">Settings</Heading>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="md">Timer</Heading>
          <HStack width="full" gap={5} marginTop={5}>
            {[
              {
                name: "Work Time",
                value: workTimeDuration,
                setter: setWorkTimeDuration,
              },
              {
                name: "Short Break",
                value: shortBreakDuration,
                setter: setShortBreakDuration,
              },
              {
                name: "Long Break",
                value: longBreakDuration,
                setter: setLongBreakDuration,
              },
            ].map((info, index) => {
              return (
                <VStack align="left" key={index}>
                  <Text>{info.name}</Text>
                  <NumberInput
                    min={1}
                    maxWidth={100}
                    defaultValue={info.value}
                    onChange={info.setter}
                    allowMouseWheel
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </VStack>
              );
            })}
          </HStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
