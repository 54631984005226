import { useState, useEffect, useContext } from "react";
import {
  Flex,
  ButtonGroup,
  useColorModeValue,
} from "@chakra-ui/react";

import TimerText from "./TimerText";
import StartStopButton from "./StartStopButton";
import SkipButton from "./SkipButton";
import StateIndicator from "./StateIndicator";
import ProgressBar from "./ProgressBar";
import SettingsContext from "../../contexts/SettingsContext";

/*

A work-break combination is a single rep.
There are four reps in a set.

...
Work -> Short Break ->
Work -> Short Break ->
Work -> Short Break ->
Work -> Long Break ->
...

*/

function getStateForRep(x) {
  if (x % 8 === 0 && x !== 0) {
    return "Long Break";
  } else if (x % 2 === 0 && x !== 0) {
    return "Short Break";
  } else {
    return "Work Time";
  }
}

function getNextTime(x, settings) {
  const nextState = getStateForRep(x);

  let nextTime = 0;

  if (nextState === "Work Time") {
    nextTime = settings.durations.workTime;
  } else if (nextState === "Short Break") {
    nextTime = settings.durations.shortBreak;
  } else if (nextState === "Long Break") {
    nextTime = settings.durations.longBreak;
  }

  return nextTime * 60;
}

export default function PomodoroTimer() {
  const settings = useContext(SettingsContext);

  const [isPaused, setIsPaused] = useState(true);
  const [x, setX] = useState(1);
  const [time, setTime] = useState(
    getNextTime(x, settings)
  );

  const countdownFinished = () => {
    setIsPaused(true);

    setTime(getNextTime(x + 1, settings));

    setX(x + 1);
  };

  useEffect(() => {
    if (isPaused) return;

    const decrementTimeByOneSecond = () => {
      setTime(time - 1);

      if (time === 0) countdownFinished();
    };

    const interval = setInterval(
      decrementTimeByOneSecond,
      1000
    );

    return () => clearInterval(interval);
  });

  return (
    <>
      <Flex
        background={useColorModeValue(
          "gray.300",
          "gray.700"
        )}
        padding={5}
        borderRadius="xl"
        flexDirection="column"
        alignItems="center"
        gap={5}
      >
        <ProgressBar
          percentage={(() => {
            const topFraction = time;
            const bottomFraction = getNextTime(x, settings);

            return (
              100 -
              (parseFloat(topFraction) / bottomFraction) *
                100
            );
          })()}
        />
        <StateIndicator state={getStateForRep(x)} />
        <TimerText time={time} />
        <ButtonGroup width="full">
          <SkipButton
            direction="backward"
            onClick={() => {
              setTime(getNextTime(x, settings));
              setIsPaused(true);
            }}
          />
          <StartStopButton
            isPaused={isPaused}
            setIsPaused={setIsPaused}
          />
          <SkipButton
            direction="forward"
            onClick={() => {
              setTime(0);
            }}
          />
        </ButtonGroup>
      </Flex>
    </>
  );
}
