import { Button } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

export default function NewTaskButton({ onOpen }) {
  return (
    <Button
      width="full"
      size="lg"
      marginTop={5}
      leftIcon={<AddIcon />}
      onClick={onOpen}
    >
      New Task
    </Button>
  );
}
