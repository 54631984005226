import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import SubTaskList from "./SubTaskList";

export default function NewTaskModal({
  isOpen,
  onClose,
  newTask,
}) {
  const { register, handleSubmit, reset } = useForm();

  const [subTasks, setSubTasks] = useState([]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(data => {
          onClose();
          reset();
          setSubTasks([]);
          newTask(data, subTasks);
        })}
      >
        <ModalHeader>Add a new task...</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            reset();
            onClose();
          }}
        />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Description</FormLabel>
            <Input {...register("description")} />
            <FormHelperText>
              Enter a short and concise description of the
              task you're going to do.
            </FormHelperText>
          </FormControl>
          <FormControl marginTop={5}>
            <FormLabel>Sub Tasks:</FormLabel>
            <SubTaskList
              subTasks={subTasks}
              setSubTasks={setSubTasks}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button type="submit">Submit</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
