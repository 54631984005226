import {
  HStack,
  Box,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion, AnimateSharedLayout } from "framer-motion";
import "./StateIndicator.css";

export default function StateIndicator({ state }) {
  const indicatorBackgroundColor = useColorModeValue(
    "gray.200",
    "gray.600"
  );

  return (
    <HStack>
      <AnimateSharedLayout>
        {["Work Time", "Short Break", "Long Break"].map(
          (name, index) => (
            <Box key={index} display="grid">
              {name === state ? (
                <Box
                  background={indicatorBackgroundColor}
                  borderRadius="lg"
                  as={motion.div}
                  layoutId="highlight"
                  width="full"
                  height="full"
                  gridRow={1}
                  gridColumn={1}
                  zIndex={1}
                  justifySelf="center"
                  alignSelf="end"
                ></Box>
              ) : null}
              <Text
                width="full"
                height="full"
                gridRow={1}
                gridColumn={1}
                zIndex={2}
                marginX={2}
                marginY={1}
                justifySelf="center"
                alignSelf="start"
              >
                {name}
              </Text>
            </Box>
          )
        )}
      </AnimateSharedLayout>
    </HStack>
  );
}
